import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Welcome from '../components/welcome'

import IndexSeoImage from '../assets/images/studio-01.jpg'

class Index extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query HomeMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }        
                }
            `}
            render={data => (
                <>
                <Layout page='index'>
                    <Seo title={`Welcome to ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={IndexSeoImage} keywords={[``]} />
                    <Hero title={data.site.siteMetadata.title} description={`After finishing his apprenticeship in 2003 Mickey has spent the last 18 years honing his skills and working along side some of the best artists in the craft. ${data.site.siteMetadata.description}`} />
                    <Welcome siteTitle={data.site.siteMetadata.title} />
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Index