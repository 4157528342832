import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Icon from '../assets/vectors/icon.svg'

class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.title = this.props.title
        this.description = this.props.description
    }
    
    render() {
        return (
            <section id='banner' className='homepage'>
                <Container>
                    <Row>
                        <Col xs='12' sm='8' md='8' lg='8'>
                            <Icon width='150' className='img-responsive hidden-md hidden-lg' />
                            <h1>Welcome to the<br />{this.title}</h1>
                            <p><strong>{this.description}</strong></p>
                            <button className='btn btn-primary center-block'><a href='/about/'>Who We Are</a></button>&nbsp;
                            <button className='btn btn-primary center-block'><a href='/ink/'>Browse the Tats!</a></button>
                        </Col>
                    </Row>
                </Container>
                <div className='arrow bounce'>
                    <Link to='scrollDown' activeClass='active' smooth='true'><FontAwesomeIcon className='arrowBtn' icon='chevron-down' /></Link>
                </div>
            </section>
        )
    }
}

export default Hero